/* eslint-disable import/prefer-default-export */

// exports the types of  destination we can dial to 
export const destinationTypes = {
  EMAIL: 'email',
  USERID: 'userId',
  SPACEID: 'spaceId',
  SIP: 'sip',
  PSTN: 'pstn'
};
